const config = {
  ENVIRONMENT_NAME: 'prod',
  forwoodId: {
    URL: 'https://id.vale.forwoodsafety.com',
    APP_CLIENT_ID: '18q58gnqiaqku8gmgqlcajqrf3',
    APIGATEWAY: 'https://9ktnk0nfyd.execute-api.us-east-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-east-2',
    URL: 'https://1hipdvpa67.execute-api.us-east-2.amazonaws.com/prod'
  },
  reactApp: {
    ENVIRONMENT_NAME: 'prod',
    VERSION: 'incidents-container-1.6.0',
    HOSTNAME: 'https://incidents.vale.forwoodsafety.com',
    INCIDENTS_ACCESS_ROLE: 'IncidentsAccess',
    CREATE_INCIDENT_URL: 'https://iframe.vale.forwoodsafety.com/node/add/isi',
    INCIDENT_LIST_URL: 'https://iframe.vale.forwoodsafety.com/isi-list',
    IFRAME_ORIGIN_URL: 'https://iframe.vale.forwoodsafety.com'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;

